import { axios } from '../helpers/axios'

export const PasswordlessService = () => {
  const createPasswordless = async ({ email }) => {
    const resp = await axios.post(
      '/auth/create-password-less',
      { email }
    )
    if (!resp) throw new Error('Ocurrió un error al solicitar un nuevo código')
    return resp
  }

  const verifyPasswordless = async ({ email, code }) => {
    const resp = await axios.post(
      '/auth/verify-password-less',
      {
        email,
        code
      }
    )
    if (resp.data.error) throw new Error(resp.data.error)
    sessionStorage.setItem('authorizationCode', resp.data.authorizationCode)
    return resp.data
  }

  return {
    createPasswordless,
    verifyPasswordless
  }
}
