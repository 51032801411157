import { axios } from '../helpers/axios'

export const PasswordService = () => {
  const resetPassword = async ({ email }) => {
    const resp = await axios.post('/auth/reset-password', { email })
    if (!resp.data) throw new Error('Ocurrió un error al tratar de restablecer contraseña')
    if (resp.data.error) throw new Error(resp.data.error)
    return resp.data
  }

  const verifyEmailResetPassword = async ({ email }) => {
    const resp = await axios.post('/auth/verify-email-reset-password', { email })
    if (!resp.data) throw new Error('Ocurrió un error al tratar de restablecer contraseña, intentelo nuevamente')
    if (resp.data.error) throw new Error(resp.data.error)
    return resp.data
  }

  const validateEmailCode = async ({ email, code }) => {
    const resp = await axios.post('/auth/validate-code-reset-password', { email, code })
    if (!resp.data) throw new Error('Ocurrió un error al tratar de restablecer contraseña, intentelo nuevamente')
    if (resp.data.error) throw new Error(resp.data.error)
    return resp.data
  }

  const changePassword = async ({ email, receivedPassword, newPassword }) => {
    const res = await axios.post('/auth/change-password', { email, tempPassword: receivedPassword, newPassword })
    if (!res) throw new Error('Ocurrió un error al tratar de cambiar contraseña')
  }

  return {
    resetPassword,
    changePassword,
    validateEmailCode,
    verifyEmailResetPassword
  }
}
