import { CARD_BODY_COLUMN_DESCRIPTION } from '../../../helpers/card-body-column-description'

const whatsappHelpApi = 'https://api.whatsapp.com/send/?phone=5218182528316&text=%C2%A1Hola%20H-E-B!&type=phone_number&app_absent=0'

export const WhatsappHelpSuccessAlert = ({ onCloseAlert, message = '' }) => (
  <div className={'alert alert-success alert-dismissible fade show d-flex ps-0 pb-0 pt-0 ' + CARD_BODY_COLUMN_DESCRIPTION} role="alert">
    <img style={{ padding: '8px' }} src="/assets/info-icon.svg"/>
    <span className="vertical-divider"></span>
    <span className="text-alert" style={{ padding: '9px 0 9px 9px', fontSize: '12px' }}>
      Mejoramos la seguridad de la cuenta. Si experimentas dificultades para ingresar <a href={whatsappHelpApi}>contáctanos por whatsapp.</a>
    </span>
    <button
      type="button"
      className="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      onClick={onCloseAlert}>
    </button>
  </div>
)
