import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

function InputPassword (props) {
  const [password, setPassword] = useState('')
  const [valid, setValid] = useState(false)
  const [inputType, setInputType] = useState('password')

  const navigate = useNavigate()

  useEffect(() => {
    props.setPassword && props.setPassword(password)
    props.setValidPassword && props.setValidPassword(valid)
  }, [props])

  const toggleInputType = () => setInputType(inputType === 'password' ? 'text' : 'password')

  const handleChange = e => {
    const inputPassword = e.target.value
    const isValidPassword = validatePassword(inputPassword)

    setValid(isValidPassword)
    setPassword(inputPassword)
    props.setPassword && props.setPassword(inputPassword)
    props.setValidPassword && props.setValidPassword(isValidPassword)
  }

  const validatePassword = password =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{}':"\\|,.<>/?])(?=.{8,})/
      .test(password)

  return (
    <div className="mb-1 input-container">
      <label htmlFor="password" className="form-label">
        {props.label}
      </label>
      <div className="input-with-icon">
        <input
          type={inputType}
          name="password"
          id="password"
          placeholder={props.placeholder || null}
          className={`form-control input-login ${password === '' ? '' : valid ? 'input-success' : 'input-error'}`}
          onChange={handleChange} />
        <i
          className={`fa icon ${password === '' ? '' : valid ? 'text-success' : 'text-danger'} ${inputType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}
          onClick={toggleInputType}/>
      </div>
      {
        props.showRecover &&
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-link mt-2 btn-link-secondary"
            onClick={() => navigate(`/reset-password?email=${props.email}`)}>
            ¿Olvidaste tu contraseña?
          </button>
        </div>
      }
    </div>
  )
}

InputPassword.propTypes = {
  label: PropTypes.string,
  showRecover: PropTypes.bool,
  setPassword: PropTypes.func,
  placeholder: PropTypes.string,
  setValidPassword: PropTypes.func
}

export default InputPassword
