/* eslint-disable react/prop-types */
import React from 'react'

// TODO: Implement modal shown in design
export const ChangePasswordNeededAlert = ({ message, onCloseAlert }) => {
  return (
    <div className="row">
      <div className="alert alert-warning fade show col-12 d-flex p-0" role="alert">
        <div
          style={{ width: '10%', borderRight: '1px solid rgba(102, 82, 0, 0.15)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <i className="fa-solid fa-circle-exclamation" style={{ color: '#FC0' }}></i>
        </div>
        <div className="text-alert d-flex" style={{ width: '90%', flexDirection: 'column', textAlign: 'center' }}>
          <span>Solicitud de cambio de contraseña.</span>
          <span>Mejoramos la seguridad de tu cuenta. Actualiza tu contraseña para ingresar a HEB en la web y app con tu mismo usuario.</span>
        </div>
      </div>
    </div>
  )
}
