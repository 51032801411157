import InputEmail from './InputEmail'
import ErrorAlert from './ErrorAlert'
import React, { useState } from 'react'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'

export const SendAccessKeyForm = ({ setEmail, submitEmail, error, clearError, loading, isMiTienda }) => {
  const [validEmail, setValidEmail] = useState(false)

  return (
    <>
      <div className="row justify-content-center" autoComplete="off">
        <form
          onSubmit={event => {
            event.preventDefault()
            if (!validEmail) return
            submitEmail().then()
          }}
          className={CARD_BODY_COLUMN_DESCRIPTION}>
          <InputEmail
            placeholder="Ingresa tu correo electrónico"
            label={'Correo electrónico'}
            setEmail={setEmail}
            setValidEmail={setValidEmail}/>
          {error &&
            <ErrorAlert
              onCloseAlert={clearError}
              message={error.message} />
          }
          <button type="submit" name="submit" hidden/>
        </form>
      </div>
      <div className="row justify-content-center" autoComplete="off">
        <div className={CARD_BODY_COLUMN_DESCRIPTION}>
          <button
            className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
            disabled={!validEmail || loading}
            onClick={submitEmail}>
            {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
            Enviar
          </button>
        </div>
      </div>
    </>
  )
}
