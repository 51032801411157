import { useEffect } from 'react'
import { toast } from 'react-toastify'

const ACCOUNT_OPERATION_RESULT_MESSAGES = {
  ACCOUNT_CREATED: 'Tu cuenta se creó exitosamente',
  PASSWORD_CHANGED: 'Tu contraseña se actualizó exitosamente',
  PHONE_CONFIRMED: 'Teléfono confirmado con éxito'
}

export const ACCOUNT_SUCCESS_CODES = Object.keys(ACCOUNT_OPERATION_RESULT_MESSAGES).reduce((acc, key) => {
  acc[key] = key
  return acc
}, {})

const ACCOUNT_OPERATION_RESULT_ERRORS = {
  ACCOUNT_NOT_CREATED: 'Ocurrió un error al intentar crear la cuenta',
  PASSWORD_NOT_CHANGED: 'Ocurrió un error al intentar actualizar la contraseña',
  PHONE_NOT_CONFIRMED: 'Ocurrió un error al intentar confirmar el teléfono'
}

export const ACCOUNT_FAILURE_CODES = Object.keys(ACCOUNT_OPERATION_RESULT_ERRORS).reduce((acc, key) => {
  acc[key] = key
  return acc
}, {})

export const showMessageNotification = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const successCode = urlParams.get('successCode')
  const failureCode = urlParams.get('failureCode')

  const message = successCode
    ? ACCOUNT_OPERATION_RESULT_MESSAGES[successCode]
    : ACCOUNT_OPERATION_RESULT_ERRORS[failureCode]

  const notify = successCode ? toast.success : toast.error

  if (message) {
    return (
      notify(message, {
        position: 'top-center',
        autoClose: 4000,
        closeButton: true,
        theme: 'colored',
        style: {
          borderRadius: '10px',
          width: '94%',
          margin: '10px auto'
        }
      })
    )
  }
}

/**
 * Show a toast notification with the result of the account operation
 * when the user is redirected after changing the password or creating an account.
 * You can use the showMessageNotification function to show the notification manually.
 * Both this hook and the showMessageNotification function depend on the successCode and failureCode
 * query params.
 */
export const useAccountOperationResultMessage = () => {
  useEffect(() => {
    showMessageNotification()
  }, [])
}
