import { axios } from '../helpers/axios'

export const LoginService = () => {
  const getWaysToLogIn = async () => {
    const waysToLogin = await axios.get('/auth/show-login-buttons')
    return waysToLogin?.data
  }

  const loginByEmail = async ({ username, password }) => {
    if (!password) throw new Error('Ingrese contraseña')
    const login = await axios.post('/password', { username, password })
    if (login.data.error) throw new Error(login.data.error)
    sessionStorage.setItem('authorizationCode', login.data.authorizationCode)
    return login
  }

  const omitPhoneValidation = async ({ code }) => {
    const resp = await axios.post(
      '/auth/omit-phone-validation',
      {
        code
      }
    )
    if (resp?.data.error) throw new Error('Ocurrió un error al guardar la información')
    return resp?.data
  }

  const sendSmsCode = async ({ code, phoneNumber }) => {
    const resp = await axios.post(
      '/auth/send-new-sms-code',
      {
        code,
        phone: phoneNumber
      }
    )
    if (resp?.data.error) throw new Error('Ocurrió un error al solicitar un nuevo código')
    return resp?.data
  }

  const updateSmsCode = async ({ code, verificationCode }) => {
    const resp = await axios.post(
      '/auth/update-sms-code',
      { code, verificationCode }
    )
    if (resp?.data.error) throw new Error(resp?.data.error)
    return resp?.data
  }

  return {
    sendSmsCode,
    loginByEmail,
    updateSmsCode,
    getWaysToLogIn,
    omitPhoneValidation
  }
}
