import React from 'react'
import { SocialMediaProviderButton } from './social-media-provider-button'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'

export const LoginProvidersButtons = ({ google, facebook, apple, state, redirectUri }) => {
  const encodedRedirectUri = encodeURIComponent(redirectUri)
  const encodedState = encodeURIComponent(state)

  return (
    <div className="row justify-content-center">
      {google &&
        <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-2`}>
          <SocialMediaProviderButton
            label="Continuar con Google"
            providerHref={`/federated/google?redirect_uri=${encodedRedirectUri}&state=${encodedState}`}
            icon={<img className="button-logo" src="/assets/google.png" alt="google"/>}/>
        </div>
      }
      {facebook && false &&
        <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-2`}>
          <SocialMediaProviderButton
            label="Continuar con Facebook"
            providerHref={`/federated/facebook?redirect_uri=${encodedRedirectUri}&state=${encodedState}`}
            icon={<img className="button-logo" src="/assets/f_logo.svg" alt="facebook"/>}/>
        </div>
      }
      {apple &&
        <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-2`}>
          <SocialMediaProviderButton
            label="Continuar con Apple"
            providerHref={`/federated/apple?redirect_uri=${encodedRedirectUri}&state=${encodedState}`}
            icon={<i className= "fa-brands fa-apple"></i>}/>
        </div>
      }
    </div>
  )
}
