import axios from 'axios'
import { APP_CONFIG } from '../config/app'

const instance = axios.create({
  baseURL: APP_CONFIG.SERVER_URI,
  headers: {
    'Cache-Control': 'no-cache'
  }
})

export { instance as axios }
