import { useNavigate } from 'react-router-dom'

function BackButton ({ callback }) {
  const navigate = useNavigate()

  return (
    <a className='back'
      style={{ cursor: 'pointer' }}
      onClick={() => callback ? callback() : navigate('/', { replace: true })}>
      <i className="fa fa-chevron-left back-button-color"></i>
      <span className="back-button-color" > Regresar</span>
    </a>
  )
}

export default BackButton
