import React, { useContext, useEffect, useState } from 'react'
import { PasswordlessService } from '../services/passwordless-service'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from './common/page-title'
import { SsoCard } from './common/sso-card'
import { SendAccessKeyForm } from './common/send-access-key-form'
import { VerifyAccessKeyForm } from './common/verify-access-key-form'
import { ClientContext } from '../context/client-context'
import { WhatsappHelpWarningAlert } from './common/whatsapp/whatsapp-help-warning-alert'

function Passwordless () {
  const queryParams = new URLSearchParams(window.location.search)
  const queryEmail = queryParams.get('email')
  const { isMiTienda } = useContext(ClientContext)
  const [step, setStep] = useState('')
  const [email, setEmail] = useState(queryEmail || '')
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!queryEmail) return setStep('create')
    setStep('verify')
    setLoading(true)
    submitEmail().then().finally(() => setLoading(false))
  }, [])

  const navigate = useNavigate()
  const submitEmail = async () => {
    if (loading) return
    setError(null)
    setLoading(true)
    try {
      await PasswordlessService().createPasswordless({ email })
      setStep('verify')
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const submitCode = async () => {
    if (loading) return
    setLoading(true)
    setError(null)
    try {
      await PasswordlessService().verifyPasswordless({ email, code })
      setStep('verify')
      sessionStorage.setItem('email', email)
      navigate('/login-success', { replace: true })
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const clearError = () => setError(null)

  return (
    <>
      <PageTitle title="Ingresa tu codigo de acceso" subtitle="Recibir clave de acceso rápido por correo"/>
      <SsoCard>
        {step === 'create' &&
          <SendAccessKeyForm
            loading={loading}
            isMiTienda={isMiTienda}
            clearError={clearError}
            setEmail={setEmail}
            submitEmail={submitEmail}
            error={error} />
        }
        {step === 'verify' &&
          <VerifyAccessKeyForm
            email={email}
            loading={loading}
            isMiTienda={isMiTienda}
            submitCode={submitCode}
            submitEmail={submitEmail}
            setCode={setCode}
            clearError={clearError}
            error={error} />
        }
      </SsoCard>
      <div className="card-body pb-3 d-flex justify-content-center bottom-whatsapp-alert">
        <WhatsappHelpWarningAlert />
      </div>
    </>
  )
}

export default Passwordless
