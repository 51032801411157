import { CARD_BODY_COLUMN_DESCRIPTION } from '../../../helpers/card-body-column-description'

const whatsappHelpApi = 'https://api.whatsapp.com/send/?phone=5218182528316&text=%C2%A1Hola%20H-E-B!&type=phone_number&app_absent=0'

export const WhatsappHelpWarningAlert = () => (
  <div className={'alert alert-warning show d-flex ps-0 pb-0 pt-0 ' + CARD_BODY_COLUMN_DESCRIPTION} role="alert">
    <img style={{ padding: '8px' }} src="/assets/warning-icon.svg"/>
    <span className="vertical-divider"></span>
    <span className="text-alert alert-message whatsapp-alert-message">
      <b>¿Estas experimentando dificultades?</b>
      <a href={whatsappHelpApi}>Contáctanos por whatsapp.</a>
    </span>
  </div>
)
