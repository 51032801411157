const osMap = {
  Windows: 'Windows',
  Macintosh: 'Mac OS',
  'Mac OS': 'Mac OS',
  Linux: 'Linux',
  Android: 'Android',
  iOS: 'iOS'
}

export const checkUserAgent = () => {
  const userAgent = window.opener ? window.opener.navigator.userAgent : window.navigator.userAgent

  let os = 'Unknown OS'
  for (const substring in osMap) {
    if ((userAgent || '').includes(substring)) {
      os = osMap[substring]
      break
    }
  }

  return os
}
