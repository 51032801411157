import React, { useContext, useState } from 'react'
import InputPassword from './common/InputPassword'
import PasswordValidationList from './common/PasswordValidationList'
import ErrorAlert from './common/ErrorAlert'
import SuccessAlert from './common/SuccessAlert'
import { PasswordService } from '../services/password-service'
import { PageTitle } from './common/page-title'
import { SsoCard } from './common/sso-card'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../helpers/card-body-column-description'
import { useNavigate } from 'react-router-dom'
import { ClientContext } from '../context/client-context'
import { ChangePasswordNeededAlert } from './common/change-password-needed-alert'
import { Disclaimer } from './common/disclaimer'
import Modal from '../helpers/modal'
import { LoginService } from '../services/login-service'

function ChangePassword () {
  const { isMiTienda } = useContext(ClientContext)
  const queryParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [validNewPassword, setValidNewPassword] = useState(false)
  const [validConfirmPassword, setValidConfirmPassword] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)
  const stateFromCookie = sessionStorage.getItem('state')

  sessionStorage.removeItem('email')
  const queryEmail = queryParams.get('email')
  sessionStorage.setItem('email', queryEmail)
  const queryCode = queryParams.get('code')

  const showPasswordChangeNeededMessage = queryParams.get('passwordChangeNeeded')
  const submit = async () => {
    if (loading) return
    setLoading(true)
    setError(null)
    try {
      if (newPassword !== confirmNewPassword) return setError('Contraseñas no coinciden')
      const email = sessionStorage.getItem('email')
      await PasswordService().changePassword({ email, newPassword, receivedPassword: queryCode })
      if (newPassword && email) {
        await LoginService().loginByEmail({ username: email, password: newPassword })
        navigate('/login-success?successCode=PASSWORD_CHANGED', { replace: true })
        return
      }
      navigate('/?success=Tu contraseña se actualizó exitosamente&successCode=PASSWORD_CHANGED', { replace: true })
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const [isModalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const renderUpdateYourPasswordAlert = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const passwordChangeNeeded = urlParams.get('migratedUser')
    if (passwordChangeNeeded) {
      return (
        <div className="row justify-content-center">
          <div
            style={{
              width: '70%',
              display: 'flex',
              padding: '4px 1rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: '#FFF5CC'
            }}
            className={'alert alert-warning alert-dismissible fade show' + (isMiTienda ? ' mi-tienda' : '')}
          >
            <div style={{ width: '10%' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M16 7.99988C16 12.4182 12.4183 15.9999 8 15.9999C3.58172 15.9999 0 12.4182 0 7.99988C0 3.5816 3.58172 -0.00012207 8 -0.00012207C12.4183 -0.00012207 16 3.5816 16 7.99988ZM7 7.99988C7 7.44759 7.44772 6.99988 8 6.99988C8.55228 6.99988 9 7.44759 9 7.99988V10.9999C9 11.5522 8.55228 11.9999 8 11.9999C7.44772 11.9999 7 11.5522 7 10.9999V7.99988ZM8 3.99988C7.44772 3.99988 7 4.44759 7 4.99988C7 5.55216 7.44772 5.99988 8 5.99988C8.55229 5.99988 9 5.55216 9 4.99988C9 4.44759 8.55229 3.99988 8 3.99988Z" fill="#FFCC00"/>
              </svg>
            </div>
            <div style={{ width: '90%', textAlign: 'center', fontSize: '12px', borderLeft: '1px solid #FFCC00' }}>
              <span style={{ fontWeight: 'bold' }}>Actualiza tu contraseña</span>
              <br />
              <span onClick={handleOpenModal} style={{ fontSize: '12px', cursor: 'pointer', textDecoration: 'underline' }}>¿Porqué tengo que cambiarla?</span>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <PageTitle title="Cambiar contraseña" subtitle="Nueva contraseña"/>
      <SsoCard>
        {renderUpdateYourPasswordAlert()}

        <form
          onSubmit={e => {
            e.preventDefault()
            submit().then()
          }}
          className="row justify-content-center"
          autoComplete="off">
          <div className={CARD_BODY_COLUMN_DESCRIPTION}>
            {showPasswordChangeNeededMessage && <ChangePasswordNeededAlert />}
            <InputPassword
              disabled={success}
              label={'Ingresa contraseña'}
              setPassword={setNewPassword}
              setValidPassword={setValidNewPassword}
              placeholder="Contraseña mínima de 8 caracteres" />
            <PasswordValidationList password={newPassword} />
            <InputPassword
              disabled={success}
              label={'Confirmar contraseña'}
              setPassword={setConfirmNewPassword}
              placeholder="Confirma tu nueva contraseña"
              setValidPassword={setValidConfirmPassword} />
            {error && <ErrorAlert message={error} onCloseAlert={() => setError(null)} />}
            {success && <SuccessAlert message={success} onCloseAlert={() => setSuccess(null)} />}
          </div>
          <button type="submit" name="submit" hidden />
        </form>
        <div className="row justify-content-center" style={{ marginTop: '20px' }}>
          <div className={CARD_BODY_COLUMN_DESCRIPTION}>
            <button
              className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
              disabled={!validNewPassword || !validConfirmPassword || loading}
              onClick={submit}>
              {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
              Guardar contraseña
            </button>
          </div>
          { stateFromCookie && <Disclaimer isMiTienda={isMiTienda} initialMessage={'Al ingresar'} /> }
        </div>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 16 16" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M16 7.99988C16 12.4182 12.4183 15.9999 8 15.9999C3.58172 15.9999 0 12.4182 0 7.99988C0 3.5816 3.58172 -0.00012207 8 -0.00012207C12.4183 -0.00012207 16 3.5816 16 7.99988ZM7 7.99988C7 7.44759 7.44772 6.99988 8 6.99988C8.55228 6.99988 9 7.44759 9 7.99988V10.9999C9 11.5522 8.55228 11.9999 8 11.9999C7.44772 11.9999 7 11.5522 7 10.9999V7.99988ZM8 3.99988C7.44772 3.99988 7 4.44759 7 4.99988C7 5.55216 7.44772 5.99988 8 5.99988C8.55229 5.99988 9 5.55216 9 4.99988C9 4.44759 8.55229 3.99988 8 3.99988Z" fill="#FFCC00"/>
            </svg>
          </div>
          <h2 style={{ fontSize: '18px', color: '#414142' }}><b>Actualización de contraseña requerida</b></h2>
          <p style={{ fontSize: '14px', color: '#64748B' }}>Mejoramos la seguridad de tu cuenta. Actualiza tu contraseña para ingresar a HEB en la web y app con tu mismo usuario.</p>
          <button
            className={'button btn btn-login w-100'}
            onClick={handleCloseModal}>
            Entendido
          </button>
        </Modal>
      </SsoCard>
    </>
  )
}

export default ChangePassword
