import React from 'react'

function SuccessAlert ({ message, onCloseAlert }) {
  return (
    <div className="alert alert-success alert-dismissible fade show  col-12 mt-3" role="alert">
      <span className="text-alert" dangerouslySetInnerHTML={{ __html: message }}></span>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={onCloseAlert}>
      </button>
    </div>
  )
};

export default SuccessAlert
