import React, { useState, useEffect } from 'react'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'

function PasswordValidationList ({ password }) {
  const [char8Valid, setChar8Valid] = useState(false)
  const [upCaseValid, setUpCaseValid] = useState(false)
  const [lowCaseValid, setLowCaseValid] = useState(false)
  const [specialCharValid, setSpecialCharValid] = useState(false)
  const [numberValid, setNumberValid] = useState(false)

  useEffect(() => {
    setChar8Valid(validateChar8())
    setUpCaseValid(validateUpCase())
    setLowCaseValid(validateLowCase())
    setSpecialCharValid(validateSpecialChar())
    setNumberValid(validateNumber())
  }, [password])

  const validateChar8 = () => /^(?=.{8,})/.test(password)
  const validateUpCase = () => /^(?=.*[A-Z])/.test(password)
  const validateLowCase = () => /^(?=.*[a-z])/.test(password)
  const validateSpecialChar = () => /^(?=.*[!@#$%^&*()_+\-=[\]{}':"\\|,.<>/?])/.test(password)
  const validateNumber = () => /^(?=.*[0-9])/.test(password)

  return (
    <div style={{ fontSize: '14px' }} className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-3`}>
      <strong>Tu contraseña debe tener:</strong>
      <p className="password-p">
        {char8Valid && <i className="fa fa-check text-success" />}
        {password && !char8Valid && <i className="fa fa-times text-danger" />}
        {' 8 Caracteres'}
      </p>
      <p className="password-p">
        {upCaseValid && <i className="fa fa-check text-success" />}
        {password && !upCaseValid && <i className="fa fa-times text-danger" />}
        {' Al menos una letra mayúscula'}
      </p>
      <p className="password-p">
        {lowCaseValid && <i className="fa fa-check text-success" />}
        {password && !lowCaseValid && <i className="fa fa-times text-danger" />}
        {' Al menos una letra minúscula'}
      </p>
      <p className="password-p">
        {specialCharValid && <i className="fa fa-check text-success" />}
        {password && !specialCharValid && <i className="fa fa-times text-danger" />}
        {' Al menos un carácter especial'}
      </p>
      <p className="password-p">
        {numberValid && <i className="fa fa-check text-success" />}
        {password && !numberValid && <i className="fa fa-times text-danger" />}
        {' Al menos un número'}
      </p>
    </div>
  )
}

export default PasswordValidationList
