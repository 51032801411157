import ErrorAlert from './ErrorAlert'
import React, { useState } from 'react'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'
import InputSmsCode from './InputSmsCode'
import { RetryCode } from './retry-code'

export const VerifyAccessKeyForm = ({ email, setCode, submitCode, error, clearError, isMiTienda, loading, submitEmail }) => {
  const [validCode, setValidCode] = useState(false)

  return (
    <>
      <div className="row justify-content-center">
        <form
          onSubmit={event => {
            event.preventDefault()
            if (!validCode) return
            submitCode().then()
          }}
          className={CARD_BODY_COLUMN_DESCRIPTION}>
          <InputSmsCode
            showChangeEmail={true}
            disabled={loading}
            label={<>Te enviamos un código de acceso al correo <strong>{email}</strong></>}
            setSmsCode={setCode}
            setValidSmsCode={setValidCode} />
          {error &&
            <ErrorAlert
              onCloseAlert={clearError}
              message={error.message} />
          }
          <button type="submit" name="submit" hidden/>
        </form>
        <RetryCode resendCode={submitEmail}/>
      </div>
      <div className="row justify-content-center">
        <div className={CARD_BODY_COLUMN_DESCRIPTION}>
          <button
            className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
            disabled={!validCode || loading}
            onClick={submitCode}>
            {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
            Confirmar
          </button>
        </div>
      </div>
    </>
  )
}
