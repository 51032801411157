import React, { useState, useEffect } from 'react'

function InputSmsCode ({ label, setSmsCode, setValidSmsCode, showChangeEmail = false }) {
  const [code, setCode] = useState('')
  const [validCode, setValidCode] = useState(false)
  useEffect(
    () => {
      setSmsCode(code)
      setValidSmsCode(Boolean(validCode))
    },
    [code, validCode]
  )

  // HELPERS
  const validateSmsCode = digit => /^[0-9]{6}$/.test(digit) || !digit
  const getIsValidInput = input => /^[0-9]{1,6}$/.test(input) || !input
  const handleChange1 = e => {
    const valid = validateSmsCode(e.target.value)
    const validInput = getIsValidInput(e.target.value)
    if (!validInput) return
    setValidCode(valid)
    setCode(e.target.value)
  }

  return (
    <div className="mt-1 mb-1 input-container text-center">
      <label htmlFor="sms-code" className="form-label" style={{ color: '#64748B' }}>{label}</label>
      {showChangeEmail &&
        <label htmlFor="sms-code" className="form-label mb-3">
          <a className='btn-link-secondary' href="/">¿Cambiar correo?</a>
        </label>
      }
      <div className="row">
        <div className="col-12">
          <input
            type="tel"
            value={code}
            name="digit-1"
            id="digit-1"
            placeholder='Ingresa código de acceso'
            className={`form-control input-sms ${code === '' || validCode ? '' : 'input-error'}`}
            maxLength={6}
            onChange={handleChange1} />
        </div>
      </div>
    </div>
  )
}

export default InputSmsCode
