import React, { useState, useContext, useEffect } from 'react'
import ErrorAlert from './common/ErrorAlert'
import { PasswordService } from '../services/password-service'
import { SsoCard } from './common/sso-card'
import { PageTitle } from './common/page-title'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../helpers/card-body-column-description'
import { useNavigate } from 'react-router-dom'
import { ClientContext } from '../context/client-context'
import InputSmsCode from './common/InputSmsCode'
import { RetryCode } from './common/retry-code'
import { Disclaimer } from './common/disclaimer'

function ResetPassword () {
  const codeSent = sessionStorage.getItem('code_sent')
  const { isMiTienda } = useContext(ClientContext)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [emailCode, setEmailCode] = useState('')
  const [validEmailCode, setValidEmailCode] = useState(false)

  const queryParams = new URLSearchParams(window.location.search)
  const queryEmail = queryParams.get('email')
  const showPasswordChangeNeededMessage = queryParams.get('passwordChangeNeeded')
  const migratedUserMessage = queryParams.get('migratedUser')

  useEffect(() => {
    if (!queryEmail) navigate('/', { replace: true })
    if (!queryEmail || codeSent) return
    sessionStorage.setItem('code_sent', 'true')
    PasswordService().verifyEmailResetPassword({ email: queryEmail }).then()
  }, [])

  const submitEmailCode = async (code) => {
    if (loading) return
    setLoading(true)
    try {
      await PasswordService().validateEmailCode({ email: queryEmail, code })
      sessionStorage.removeItem('code_sent')
      if (migratedUserMessage) {
        navigate(`/changepassword?email=${queryEmail}&code=${emailCode}&migratedUser=true`, { replace: true })
        return
      }
      navigate(`/changepassword?email=${queryEmail}&code=${emailCode}${showPasswordChangeNeededMessage ? '&passwordChangeNeeded=true' : ''}`, { replace: true })
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const sendEmailCode = async () => {
    if (loading) return
    setLoading(true)
    try {
      await PasswordService().verifyEmailResetPassword({ email: queryEmail })
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle title="Confirma tu correo electrónico" subtitle="Recuperar contraseña"/>
      <SsoCard>
        <div className="row justify-content-center">
          <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mb-2`}>
            {error &&
              <ErrorAlert
                onCloseAlert={() => setError(null)}
                message={error.message} />
            }
          </div>
          <form
            style={{ color: '#64748B' }}
            onSubmit={e => {
              e.preventDefault()
              submitEmailCode(emailCode).then()
            }}
            className={`${CARD_BODY_COLUMN_DESCRIPTION}`}>
            <InputSmsCode
              showChangeEmail={true}
              disabled={loading}
              label={<>Te enviamos un código de acceso al correo <strong>{queryEmail}</strong></>}
              setSmsCode={setEmailCode}
              setValidSmsCode={setValidEmailCode} />
            <button type="submit" name="submit" hidden/>
          </form>
          <RetryCode resendCode={sendEmailCode}/>
          <div className={CARD_BODY_COLUMN_DESCRIPTION}>
            <button
              className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
              disabled={!validEmailCode || loading}
              onClick={() => submitEmailCode(emailCode)}>
              {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
              Continuar
            </button>
          </div>
          <Disclaimer isMiTienda={isMiTienda} initialMessage={'Al ingresar'} />
        </div>
      </SsoCard>
    </>
  )
}

export default ResetPassword
