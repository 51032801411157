import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'
import Countdown, { zeroPad } from 'react-countdown'
import React, { useCallback, useState } from 'react'

export const RetryCode = ({ resendCode }) => {
  const [date, setDate] = useState(Date.now() + 60000)

  const renderer = useCallback(({ minutes, seconds, api: { start, isCompleted } }) => {
    if (isCompleted()) {
      return <Retry resendCode={resendCode} start={start} setDate={setDate} />
    } else {
      return <span style={{ height: '1rem', color: '#64748B' }}>Si no recibiste el código, revisa tu carpeta de correos no deseados o inténtalo de nuevo en <strong>{zeroPad(minutes)}:{zeroPad(seconds)}</strong> minutos</span>
    }
  }, [date])

  return (
    <div className={`${CARD_BODY_COLUMN_DESCRIPTION} text-center mb-3 mt-2`}>
      <Countdown
        key='countdown'
        date={date}
        renderer={renderer}/>
    </div>
  )
}

const Retry = ({ resendCode, start, setDate }) => (
  <span style={{ height: '1rem', fontSize: '12px' }}>
    Si no recibiste el código, revisa tu carpeta de correos no deseados o
    <a
      onClick={() => {
        setDate(Date.now() + 60000)
        resendCode().then(() => start())
      }}
      style={{ color: '#4B8DEB', textDecoration: 'none', fontSize: '12px', padding: '0 4px', border: 'none' }}
      className="btn btn-link signup">
      <b>inténtalo de nuevo</b>
    </a>
  </span>
)
