/* eslint-disable react/prop-types */
import React from 'react'

function ErrorAlert ({ message, onCloseAlert }) {
  return (
    <div className="row" style={{ margin: 0 }}>
      <div
        role="alert"
        style={{ backgroundColor: '#FFD8D6', color: '#CC2F26', padding: '0 2rem 0 0', borderRadius: '8px' }}
        className="alert alert-danger alert-dismissible fade show col-12">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={onCloseAlert}>
        </button>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ borderRight: '1px solid #dbc0b6', padding: '0 .5rem' }}>
            <svg
              style={{ height: 'fit-content', paddingTop: '1rem' }}
              width="20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512">
              <path
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
            </svg>
          </div>
          <div style={{ color: '#000', padding: '1rem 0' }} className='text-alert'>
            {ERROR_TITLE_BY_MESSAGE[message] ? <><span style={{ fontWeight: '600' }}>{ERROR_TITLE_BY_MESSAGE[message]}</span><br/></> : ''}
            <span className="text-alert" dangerouslySetInnerHTML={{ __html: message }}></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorAlert

const ERROR_TITLE_BY_MESSAGE = {
  'Por favor, prueba con un número diferente para continuar.': 'Este número ya está vinculado a otra cuenta'
}
