import React, { useRef } from 'react'

const Modal = ({ isOpen, onClose, children }) => {
  const modalContentRef = useRef(null)

  const handleOverlayClick = (e) => {
    if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
      onClose()
    }
  }
  if (!isOpen) {
    return null
  }

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" ref={modalContentRef}>
        <button className="modal-close-button" onClick={onClose}>x</button>
        {children}
      </div>
    </div>
  )
}

export default Modal
