import React, { useContext, useEffect } from 'react'
import { ClientContext } from '../../context/client-context'
import BackButton from './BackButton'

export const PageTitle = ({ title, subtitle, showBackButton = true }) => {
  const { isMiTienda } = useContext(ClientContext)

  useEffect(() => {
    document.title = subtitle + ' - ' + (isMiTienda ? 'Mi Tienda' : 'HEB')
  }, [subtitle, isMiTienda])

  return (
    <div className="d-flex flex-column g-3 mb-1 mb-md-0">
      <div className="title">
        <div className="row mb-3 mb-md-1">
          {!!showBackButton && (
            <div className="col-12 d-flex ms-2">
              <BackButton />
            </div>
          )}
        </div>
        <h3 className="responsive-title" style={{ marginBottom: 0, padding: '0 32px' }}>
          <strong>{title}</strong>
        </h3>
      </div>
    </div>
  )
}
