import { axios } from '../helpers/axios'

export const SignupService = () => {
  const signupWithEmail = async ({ name, username, password, lastName }) => {
    const payload = { first_name: name, last_name: lastName, username, password }
    const register = await axios.post('/auth/signup', payload, { 'Content-Type': 'json/app' })

    if (register.data.error) throw new Error(register.data.error)
  }

  const validateEmailCode = async ({ email, code }) => {
    const res = await axios.post('/auth/verify-user-account', { email, code }, { 'Content-Type': 'json/app' })
    if (!res.data) throw new Error('Ocurrió un error al tratar de validar el correo, intentelo nuevamente')
    if (res.data.error) throw new Error(res.data.error)
    return res.data
  }

  const resendEmailCode = async ({ email }) => {
    const res = await axios.post('/auth/send-new-user-account-code', { email }, { 'Content-Type': 'json/app' })
    if (!res.data) throw new Error('Ocurrió un error al tratar de volver a enviar el código, intentelo nuevamente')
    if (res.data.error) throw new Error(res.data.error)
    return res.data
  }

  return {
    signupWithEmail,
    resendEmailCode,
    validateEmailCode
  }
}
