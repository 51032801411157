import { PageTitle } from './common/page-title'
import { SsoCard } from './common/sso-card'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../helpers/card-body-column-description'
import ErrorAlert from './common/ErrorAlert'
import InputSmsCode from './common/InputSmsCode'
import { RetryCode } from './common/retry-code'
import React, { useContext, useState } from 'react'
import { ClientContext } from '../context/client-context'
import { useNavigate } from 'react-router-dom'
import { SignupService } from '../services/signup-service'
import { LoginService } from '../services/login-service'
import { WhatsappHelpWarningAlert } from './common/whatsapp/whatsapp-help-warning-alert'

export const ConfirmEmail = () => {
  const { isMiTienda } = useContext(ClientContext)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [emailCode, setEmailCode] = useState('')
  const [validEmailCode, setValidEmailCode] = useState(false)

  const queryParams = new URLSearchParams(window.location.search)
  const queryEmail = queryParams.get('email')

  const submitEmailCode = async (code) => {
    if (loading) return
    setLoading(true)
    try {
      const pwd = sessionStorage.getItem('pwd')
      const email = sessionStorage.getItem('email')
      await SignupService().validateEmailCode({ email: queryEmail, code })
      if (pwd && email) {
        await LoginService().loginByEmail({ username: email, password: pwd })
        navigate('/login-success?successCode=ACCOUNT_CREATED', { replace: true })
        return
      }
      navigate('/?success=Usuario creado correctamente&successCode=ACCOUNT_CREATED', { replace: true })
    } catch (err) {
      setError(err)
    } finally {
      sessionStorage.removeItem('pwd')
      setLoading(false)
    }
  }

  const sendEmailCode = async () => {
    if (loading) return
    setLoading(true)
    try {
      await SignupService().resendEmailCode({ email: queryEmail })
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle title='Confirma tu correo electrónico' subtitle="Confirmar Email"/>
      <SsoCard>
        <div className="row justify-content-center">
          <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mb-2`}>
            {error &&
              <ErrorAlert
                onCloseAlert={() => setError(null)}
                message={error.message} />
            }
          </div>
          <form
            style={{ color: '#64748B' }}
            onSubmit={e => {
              e.preventDefault()
              submitEmailCode(emailCode).then()
            }}
            className={`${CARD_BODY_COLUMN_DESCRIPTION}`}>
            <InputSmsCode
              showChangeEmail={true}
              disabled={loading}
              label={<>Te enviamos un código de verificación al correo <strong>{queryEmail}</strong></>}
              setSmsCode={setEmailCode}
              setValidSmsCode={setValidEmailCode} />
            <button type="submit" name="submit" hidden/>
          </form>
          <RetryCode resendCode={sendEmailCode}/>
          <div className={CARD_BODY_COLUMN_DESCRIPTION}>
            <button
              className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
              disabled={!validEmailCode || loading}
              onClick={() => submitEmailCode(emailCode)}>
              {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
              Confirmar
            </button>
          </div>
        </div>
      </SsoCard>
      <div className="card-body pb-3 d-flex justify-content-center bottom-whatsapp-alert">
        <WhatsappHelpWarningAlert />
      </div>
    </>
  )
}
