import React, { useContext, useState } from 'react'
import InputText from './common/InputText'
import InputPassword from './common/InputPassword'
import ErrorAlert from './common/ErrorAlert'
import PasswordValidationList from './common/PasswordValidationList'
import { SignupService } from '../services/signup-service'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from './common/page-title'
import { SsoCard } from './common/sso-card'
import { Disclaimer } from './common/disclaimer'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../helpers/card-body-column-description'
import { ClientContext } from '../context/client-context'
import { MailLabel } from './common/mail-label'

function SignUp () {
  const { isMiTienda } = useContext(ClientContext)
  const [name, setName] = useState('')
  const [validName, setValidName] = useState(false)
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const queryEmail = queryParams.get('email')

  const stateFromCookie = sessionStorage.getItem('state')

  const authenticationStrategy = sessionStorage.getItem('socialNetwork') || 'email'

  const submit = async ({ name, username, password, lastName }) => {
    if (loading) return
    setError(null)
    setLoading(true)
    try {
      await SignupService().signupWithEmail({ name, username, lastName, password })
      sessionStorage.setItem('pwd', password)
      sessionStorage.setItem('email', username)
      navigate(`/confirm-email/?email=${queryEmail}`)
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle title="Crear una nueva cuenta" subtitle="Crea tu cuenta"/>
      <SsoCard>
        <div className="row justify-content-center">
          <form className={`${CARD_BODY_COLUMN_DESCRIPTION}`} autoComplete="off">
            <MailLabel authenticationStrategy={authenticationStrategy} email={queryEmail}/>
            <InputText
              id='firstName'
              label={'Nombre'}
              setText={setName}
              placeholder="Ingresa tu nombre"
              setValidText={setValidName} />
            <InputText
              id='lastName'
              label={'Apellidos'}
              setText={setLastName}
              placeholder="Ingresa tus apellidos"
              setValidText={setValidName} />
            <InputPassword
              label={'Contraseña'}
              setPassword={setPassword}
              placeholder="Contraseña mínimo 8 caracteres"
              setValidPassword={setValidPassword}
              showRecover={false} />
            {error &&
              <ErrorAlert
                onCloseAlert={() => setError(null)}
                message={error.message} />
            }
          </form>
          <PasswordValidationList
            password={password} />
          <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-3`}>
            <button
              className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
              disabled={!validName || !validPassword || loading}
              onClick={() => submit({ name, lastName, username: queryEmail, password })}>
              {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
              Regístrate
            </button>
          </div>
          {stateFromCookie && <Disclaimer isMiTienda={isMiTienda} />}
        </div>
      </SsoCard>
    </>
  )
}

export default SignUp
