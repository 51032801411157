import React from 'react'

export const Divider = ({ description }) => (
  <>
    {description
      ? (
        <div className="row justify-content-center mt-3 mb-2 align-items-center">
          <div className="col-3 p-0">
            <hr className="back-button-color" />
          </div>
          <div className="d-flex justify-content-center col-1 back-button-color">
            {description}
          </div>
          <div className="col-3 p-0">
            <hr className="back-button-color" />
          </div>
        </div>
        )
      : (
        <hr className="divider complete-divider" />
        )
    }
  </>
)
