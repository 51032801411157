import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

function InputPhoneNumber (props) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [valid, setValid] = useState(false)
  const inputPhoneNumber = useRef()

  useEffect(() => {
    props.setPhoneNumber(phoneNumber)
    props.setValidPhoneNumber(valid)
  }, [phoneNumber, valid])

  const handleChange = () => {
    const phoneNumberValue = inputPhoneNumber.current.value.replace(/\(|\)/g, '').replace(/\s/g, '')
    setValid(validatePhoneNumber(phoneNumberValue))
    setPhoneNumber(phoneNumberValue)
  }

  useEffect(handleChange, [phoneNumber])

  const validatePhoneNumber = phoneNumber => /^[0-9]{10}$/.test(phoneNumber)

  return (
    <div className="mt-1 mb-4 input-container">
      <div className="input-with-icon">
        <label
          form="digit-1"
          style={{ color: '#94A3B8', fontSize: '12px', position: 'absolute', left: '12px', top: '4px' }}>
          Teléfono
        </label>
        <input
          disabled={props.disabled}
          type="tel"
          name="phone-number"
          id="phone-number"
          ref={inputPhoneNumber}
          style={{ paddingTop: '20px', height: '50px' }}
          onChange={handleChange}
          placeholder={props.placeholder}
          className={`form-control input-login ${phoneNumber === '' ? '' : valid ? 'input-success' : 'input-error'}`}/>
        <i className={`fa icon fa-check text-success ${valid ? '' : 'd-none'}`}/>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '6px' }}>
        <span style={{ color: 'rgb(100, 116, 139);', fontSize: '12px' }}>{(phoneNumber?.length || 0)}/10 caracteres</span>
      </div>
    </div>
  )
}

InputPhoneNumber.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  setValidPhoneNumber: PropTypes.func
}

export default InputPhoneNumber
