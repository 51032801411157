import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function InputText ({ value, setText, setValidText, id, label, placeholder }) {
  const [text, setComponentText] = useState(value || '')
  const [valid, setValid] = useState(Boolean(value))

  useEffect(() => {
    setText(text)
    setValidText(valid)
  }, [text, valid])

  const handleChange = e => {
    setValid(validateText(e.target.value))
    setComponentText(e.target.value)
  }

  const validateText = text => text.trim().length > 0

  return (
    <div className="mt-1 mb-4 input-container">
      <label htmlFor="email" className="form-label">{label}</label>
      <div className="input-with-icon">
        <input
          value={text}
          id={id}
          type="text"
          name={id}
          placeholder={placeholder || null}
          className={`form-control input-login ${text === '' ? '' : valid ? 'input-success' : 'input-error'}`}
          onChange={handleChange} />
        <i className={`fa icon fa-check text-success ${valid ? '' : 'd-none'}`}/>
      </div>
    </div>
  )
}

InputText.propTypes = {
  label: PropTypes.string,
  setText: PropTypes.func,
  setValidText: PropTypes.func,
  placeholder: PropTypes.string
}

export default InputText
